$(function () {
    initNav();
    initSlick();
    initAanbodCarousel();
    initNieuwbouwCarousel();
    initTaxatieTabel();
    initUspsCarousel();
    initDropdown();
    initMatchHeight();
    initObject();
    initNieuwbouw();
    initScroll();
    initTimeline();
    initContact();
    initFaq();
    initReviews();
    initLazyLoad();
    initReadMoreDetails();

    if (getCookie('hide-pop-up') != 'yes') {
        DatHuis.pop('​kompas.nl', {
            position: 'bottomRight',
            backgroundColor: '#D6D1CB',
            textColor: '#071D49',
            headerText: 'WAT IS MIJN HUIS WAARD?',
            subText: 'Bereken eenvoudig de geschatte waarde van jouw huis!',
            buttonText: 'Bereken',
            mobile: {
                subTextHidden: true
            },
            maxWidth: '300px',
            headerTextSize: '20px',
            subTextSize: '16px',
            buttonTextSize: '16px',
        });

        $('#dathuis_waardeschatting_close_button').click(function () {
            writeCookie('hide-pop-up', 'yes', 1);
        });
    }
});

function initReadMoreDetails() {
    const detailsContent = document.getElementById('details-content');
    const toggleBtn = document.getElementById('toggle-details');

    toggleBtn.addEventListener('click', (e) => {
        e.preventDefault();
        detailsContent.classList.toggle('open');
        toggleBtn.classList.toggle('open');

        if (detailsContent.classList.contains('open')) {
            toggleBtn.classList.toggle('d-none');
        } else {
            toggleBtn.innerHTML = '<span class="circleplus"><i class="icon-plus"></i></span> Lees meer';
        }
    });
}

function initLazyLoad() {
    const items = document.querySelectorAll('[data-src]');
    let elements = [];

    for (var i = 0; i < items.length; i++) {
        if (!items[i].classList.contains('swiper-lazy')) {
            elements.push(items[i]);
        }
    }

    this.processScroll(elements);

    window.addEventListener('scroll', () => {
        this.processScroll(elements);
    });
}

function processScroll(elements) {
    for (var i = 0; i < elements.length; i++) {
        if (this.elementInViewport(elements[i])) {
            this.loadImage(elements[i], function () {
                elements.splice(i, 1);
            });
        }
    }
}

function loadImage(el, fn) {
    let src = el.getAttribute('data-src');

    if (el instanceof HTMLImageElement) {
        if (!el.src) {
            if (window.getComputedStyle(el, null).display == 'inline') {
                el.style.display = 'inline-block';
            }

            el.src = src;
        }
    } else {
        if (!el.style.backgroundImage) {
            el.style.backgroundImage = 'url(' + src + ')';
        }
    }
}

function elementInViewport(el) {
    let rect = el.getBoundingClientRect();

    return (
        (rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
        (rect.bottom >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    );
}

function writeCookie(key, value, days) {
    var date = new Date();

    // Default at 365 days.
    days = days || 365;

    // Get unix milliseconds at current time plus number of days
    date.setTime(+date + (days * 86400000)); //24 * 60 * 60 * 1000

    document.cookie = key + "=" + value + "; expires=" + date.toGMTString() + "; path=/";

    return value;
}

function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length == 2) return parts.pop().split(";").shift();
}

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        $nav.children('ul').attr('style', false);
        initObject();
    }, 200));
});

function initNav() {
    $btn_navigation = $('header .nav-toggle');
    $nav = $('header nav');

    $btn_navigation.click(function (e) {
        e.stopPropagation();
        e.preventDefault();

        if ($(window).width() < 980) {
            if ($nav.css('visibility') === 'hidden' || $nav.is(':hidden')) {
                $nav.slideDown();
                $('body').addClass('nav-open');
            } else {
                $nav.slideUp();
                $('body').removeClass('nav-open');
            }
        }
    });

    if ($nav.find('ul').length > 0) {
        $li = $nav.find('li');

        $li.has('ul').children('a').click(function (e) {

            $(this).closest('ul').children('li').find('ul').each(function (i, ul) {
                if ($(ul) != $(this).next('ul')) {
                    $(this).prev('a').children('span').removeClass('toggle');

                    if ($(window).width() < 1024) {
                        $(ul).slideUp();
                    }
                }
            });

            if ($(this).next('ul').css('visibility') === 'hidden' || $(this).next('ul').is(':hidden')) {
                e.preventDefault();

                if ($(window).width() < 1024) {
                    $(this).next('ul').slideDown();
                }
                $(this).children('span').addClass('toggle');
            }
        });
    }

    $(window).scroll(function () {
        if ($(this).scrollTop() > 30) {
            $('.nav_wrapper').addClass('fixed');
        } else {
            $('.nav_wrapper').removeClass('fixed');
        }
    });
}

function initSlick() {
    if ($('#header_carousel').length > 0) {
        // $('#header_carousel').slick({
        //     dots: false,
        //     arrows: false,
        //     autoplay: false
        // });
    }
}

function initReviews() {
    if ($('#reviewSlider').length > 0) {
        $('#reviewSlider').slick({
            dots: false,
            arrows: false,
            autoplay: true,
            adaptiveHeight: true,
            useTransform: false,
            slidesToShow: 2,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initAanbodCarousel() {
    if ($('#aanbod_carousel').length > 0) {
        $('#aanbod_carousel').slick({
            arrows: true,
            slidesToShow: 3,
            prevArrow: $('#aanbod_wrapper .icon-arrow-left, #properties .icon-arrow-left'),
            nextArrow: $('#aanbod_wrapper .icon-arrow-right, #properties .icon-arrow-right'),

            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initTaxatieTabel() {
    if ($('#taxatietabel').length > 0) {
        $('#taxatietabel').slick({
            arrows: false,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initNieuwbouwCarousel() {
    if ($('.gallery_carousel').length > 0) {
        $('.gallery_carousel').slick({
            arrows: true,
            slidesToShow: 4.3,
            prevArrow: $('.gallery .top .icon-arrow-left'),
            nextArrow: $('.gallery .top .icon-arrow-right'),

            responsive: [
                {
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 3.5,
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                    }
                }
            ]
        });
    }
}

function initUspsCarousel() {
    if ($('#usps').length > 0) {
        $('#usps').slick({
            arrows: false,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }
}

function initDropdown() {
    $('.dropdown').each(function (i, el) {
        $(el).on('click', function () {
            $(el).find('ul').fadeToggle(150);

            $(el).find('a').on('click', function (e) {
                $(el).find('.value').text($(this).text());
            });
        });
    });
}

function initMatchHeight() {
    if ($('.matchheight').length > 0) {
        $('.matchheight').matchHeight();
    }
}

function initNieuwbouw() {
    if ($('.lightbox').length > 0) {
        $('.lightbox').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            },
            callbacks: {
                resize: changeImgSize,
                imageLoadComplete:changeImgSize,
                change:changeImgSize
            }
        });
    }
    function changeImgSize(){
        var img = this.content.find('img');
        img.css('max-height', '800px');
    }

    initObjectPano();

    // Gallery icon
    $('.icon-zoom-in').on('click', function (e) {
        e.preventDefault();
        $('.lightbox').first().click();
    });
}

function initObject() {
    if ($('#object').length > 0) {
        $('#omschrijving + a').on('click', function (e) {
            e.preventDefault();

            $('#omschrijving').addClass('show');
            $(this).hide();
        });

        // Lightbox
        if ($('.lightbox').length > 0) {
            console.log('ksajdfoaf')
            $('.lightbox').magnificPopup({
                type: 'image',
                gallery: {
                    enabled: true
                }
            });
        }

        initObjectPano();

        // Gallery icon
        $('.icon-zoom-in').on('click', function (e) {
            e.preventDefault();
            $('.lightbox').first().click();
        });
    }
}

function initObjectPano() {
    $('[id^=pano-]').each(function (index) {
        id = $(this).attr('data-id');
        data = {
            js: '/js/pano/pano.js',
            swf: '/js/pano/pano.swf',
            // xml: window.location.origin + window.location.pathname + '/pano/' + id + '.xml',
            target: 'pano-' + id,
            html5: 'prefer',
            passQueryParameters: true
        };
        embedpano(data);
    });
}

function initScroll() {
    if ($('.icon-scroll').length) {
        $('.icon-scroll').on('click', function (e) {
            e.preventDefault();
            $('html,body').stop().animate({
                scrollTop: $("#homepage").offset().top - 70
            }, 300, 'linear');
        });
    }
}

function initTimeline() {
    if ($('#overons').length) {
        $('.timeline .line').each(function () {
            var height = $(this).find('.text span p').outerHeight();
            $(this).find('.text span').css('height', height + 40);

            if ($(window).width() >= 1024) {
                $(this).find('.text').css('height', $(this).outerHeight());
                $(this).find('.image').css('height', $(this).outerHeight());
            } else {
                $(this).find('.text').css('height', height + 40);
                $(this).find('.image').css('height', '120px');
            }
        });
    }
}

function initContact() {
    if ($('#contact').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}


function initFaq() {
    if ($('#faq').length > 0) {
        // if ($('.faq_wrapper h3').length > 0) {
        //
        //     $('.faq_wrapper h3').on('click', function (e) {
        //         if ($(this).next('ul').is(':visible')) {
        //             $(this).next('ul').hide();
        //         } else {
        //             $('.questions').hide();
        //             $(this).next('ul').show();
        //
        //             $('html, body').animate({
        //                 scrollTop: $(this).offset().top - 100
        //             }, 1000);
        //         }
        //     });
        // } else {
        //     $('.questions').show();
        // }

        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}